const MENU_SET = {
  CRM: 'CRM',
  PU: 'PU',
  ADC: 'ADC',
};

const MENU_SECTIONS = {
  CRM_DASHBOARD: 'CRM_DASHBOARD',
  MARKETING_DASHBOARD: 'MARKETING_DASHBOARD',
  PRODUCTS: 'PRODUCTS',
  COMBO_PRODUCTS: 'COMBO_PRODUCTS',
  CUSTOMERS: 'CUSTOMERS',
  ORDERS: 'ORDERS',
  BULK_PRESALE_SETUP: 'BULK_PRESALE_SETUP',
  FAILED_ORDERS: 'FAILED_ORDERS',
  CUSTOM_TILES: 'CUSTOM_TILES',
  HOMEPAGE_SETUP: 'HOMEPAGE_SETUP',
  PROMO_SETUP: 'PROMO_SETUP',
  REFERRAL: 'REFERRAL',
  BLOGS: 'BLOGS',
  UPLOADS: 'UPLOADS',
  PU_DASHBOARD: 'PU_DASHBOARD',
  CREATE_QR_CODE: 'CREATE_QR_CODE',
  LINK_PRODUCTS_SKID: 'LINK_PRODUCTS_SKID',
  PRODUCT_LINKED_SKIDS: 'PRODUCT_LINKED_SKIDS',
  INVOICES: 'INVOICES',
  ON_SCAN_DEMO: 'ON_SCAN_DEMO',
  USERS: 'USERS',
  AUTH_CODES: 'AUTH_CODES',
  ROLES: 'ROLES',
  SPECIAL_PAGES: 'SPECIAL_PAGES',
  NOTIFICATION: 'NOTIFICATION',
  APP_UPDATE: 'APP_UPDATE',
  HB_POINTS: 'HB_POINTS',
  HB_PRIME: 'HB_PRIME',
  PROVISIONAL_CATEGORY: 'PROVISIONAL_CATEGORY',
  ADC_SETUP: 'ADC_SETUP',
  LOGISTIC_TRACKING: 'LOGISTIC_TRACKING',
  SYSTEM_PROPERTY: 'SYSTEM_PROPERTY',
  SEO_SETTING: 'SEO_SETTING',
  ADC_PINCODE: 'ADC_PINCODE',
  CRON_JOBS: 'CRON_JOBS',
  HB_MONEY: 'HB_MONEY',
  SHORT_URLS: 'SHORT_URLS',
};

const MENU_AUTH_CODE = {
  VIEW_MARKETING_DASHBOARD: 'VIEW_MARKETING_DASHBOARD',
  VIEW_REGULAR_PRODUCTS_CHART: 'VIEW_REGULAR_PRODUCTS_CHART',
  LOGIN_AS_CUSTOMER: 'LOGIN_AS_CUSTOMER',
  COPY_OFC_LINK: 'COPY_OFC_LINK',
  RECHARGE_WALLET: 'RECHARGE_WALLET',
  BULK_WALLET_RECHARGE: 'BULK_WALLET_RECHARGE',
  VIEW_HB_MONEY_SECTION: 'VIEW_HB_MONEY_SECTION',
  VIEW_WALLET_CUSTOMERS: 'VIEW_WALLET_CUSTOMERS',
  CUSTOM_TILES_SECTION: 'CUSTOM_TILES_SECTION',
  VIEW_CUSTOM_TILE: 'VIEW_CUSTOM_TILE',
  ADD_TO_INVOICE: 'ADD_TO_INVOICE',
  VIEW_INVOICE: 'VIEW_INVOICE',
  PRODUCTS_SECTION: 'PRODUCTS_SECTION',
  ADD_PRODUCT: 'ADD_PRODUCT',
  EDIT_PRODUCT: 'EDIT_PRODUCT',
  EDIT_PRICE: 'EDIT_PRICE',
  EDIT_DEAL: 'EDIT_DEAL',
  TOGGLE_WEEKEND_SPL_SKU: 'TOGGLE_WEEKEND_SPL_SKU',
  EDIT_SEO_DETAILS: 'EDIT_SEO_DETAILS',
  SEARCH_PRODUCT: 'SEARCH_PRODUCT',
  VIEW_COMBO_PRODUCTS: 'VIEW_COMBO_PRODUCTS',
  ADD_COMBO_PRODUCT: 'ADD_COMBO_PRODUCT',
  EDIT_COMBO_PRODUCT: 'EDIT_COMBO_PRODUCT',
  CUSTOMERS_SECTION: 'CUSTOMERS_SECTION',
  ADD_CUSTOMER: 'ADD_CUSTOMER',
  SEARCH_CUSTOMER: 'SEARCH_CUSTOMER',
  VIEW_CUSTOMER: 'VIEW_CUSTOMER',
  ORDER_FOR_CUSTOMER: 'ORDER_FOR_CUSTOMER',
  ORDERS_SECTION: 'ORDERS_SECTION',
  ADD_BULK_PRESALE: 'ADD_BULK_PRESALE',
  SEARCH_ORDER: 'SEARCH_ORDER',
  VIEW_ORDER: 'VIEW_ORDER',
  CANCEL_ORDER: 'CANCEL_ORDER',
  RESCHEDULE_ORDER: 'RESCHEDULE_ORDER',
  UPDATE_ORDER_STATUS: 'UPDATE_ORDER_STATUS',
  VIEW_ORDER_OTP: 'VIEW_ORDER_OTP',
  VIEW_FAILED_ORDER: 'VIEW_FAILED_ORDER',
  UPDATE_FAILED_ORDER: 'UPDATE_FAILED_ORDER',
  PROCESS_CRR: 'PROCESS_CRR',
  VIEW_EXPECTED_INVENTORY: 'VIEW_EXPECTED_INVENTORY',
  SIMULATE_ORDER: 'SIMULATE_ORDER',
  ADD_CUSTOM_TILE: 'ADD_CUSTOM_TILE',
  EDIT_CUSTOM_TILE: 'EDIT_CUSTOM_TILE',
  SEARCH_SKU_INVOICE: 'SEARCH_SKU_INVOICE',
  GENERATE_DRAFT_INVOICE: 'GENERATE_DRAFT_INVOICE',
  ADD_SKU_TO_INVOICE: 'ADD_SKU_TO_INVOICE',
  LINK_SKID: 'LINK_SKID',
  EDIT_SKID_QUANTITY: 'EDIT_SKID_QUANTITY',
  REMOVE_SKU_FROM_INVOICE: 'REMOVE_SKU_FROM_INVOICE',
  GENERATE_INVOICE: 'GENERATE_INVOICE',
  LINK_VEHICLE_TO_INVOICE: 'LINK_VEHICLE_TO_INVOICE',
  PRINT_INVOICE: 'PRINT_INVOICE',
  ON_SCAN_DEMO_SECTION: 'ON_SCAN_DEMO_SECTION',
  HOMEPAGE_SECTION: 'HOMEPAGE_SECTION',
  UPDATE_HOMEPAGE: 'UPDATE_HOMEPAGE',
  PROMO_SECTION: 'PROMO_SECTION',
  VIEW_PROMO: 'VIEW_PROMO',
  ADD_PROMO: 'ADD_PROMO',
  EDIT_PROMO: 'EDIT_PROMO',
  REFERRAL_SECTION: 'REFERRAL_SECTION',
  BLOGS_SECTION: 'BLOGS_SECTION',
  UPLOADS_SECTION: 'UPLOADS_SECTION',
  CREATE_QR_CODE_SECTION: 'CREATE_QR_CODE_SECTION',
  SEARCH_QR_SKU: 'SEARCH_QR_SKU',
  GENERATE_QR: 'GENERATE_QR',
  GENERATE_SKU_PRINT: 'GENERATE_SKU_PRINT',
  LINK_PRODUCT_SKID_SECTION: 'LINK_PRODUCT_SKID_SECTION',
  PRODUCT_LINKED_SKID_SECTION: 'PRODUCT_LINKED_SKID_SECTION',
  INVOICE_SECTION: 'INVOICE_SECTION',
  CREATE_INVOICE: 'CREATE_INVOICE',
  SEARCH_INVOICE: 'SEARCH_INVOICE',
  VIEW_PRODUCTS: 'VIEW_PRODUCTS',
  VIEW_CATEGORY_SETUP: 'VIEW_CATEGORY_SETUP',
  UPSERT_CATEGORY_SETUP: 'UPSERT_CATEGORY_SETUP',
  DELETE_CATEGORY_SETUP: 'DELETE_CATEGORY_SETUP',
  VIEW_USERS: 'VIEW_USERS',
  EDIT_USER_ROLES: 'EDIT_USER_ROLES',
  RESET_PASSWORD: 'RESET_PASSWORD',
  VIEW_AUTH_CODES: 'VIEW_AUTH_CODES',
  ADD_AUTH_CODES: 'ADD_AUTH_CODES',
  EDIT_AUTH_CODES: 'EDIT_AUTH_CODES',
  VIEW_ROLES: 'VIEW_ROLES',
  ADD_NEW_ROLE: 'ADD_NEW_ROLE',
  EDIT_ROLE_MAPPING: 'EDIT_ROLE_MAPPING',
  EDIT_ROLE_NAME: 'EDIT_ROLE_NAME',
  VIEW_SPECIAL_PAGES: 'VIEW_SPECIAL_PAGES',
  ADD_SPECIAL_PAGES: 'ADD_SPECIAL_PAGES',
  EDIT_SPECIAL_PAGES: 'EDIT_SPECIAL_PAGES',
  VIEW_NOTIFICATION: 'VIEW_NOTIFICATION',
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  EDIT_NOTIFICATION: 'EDIT_NOTIFICATION',
  VIEW_APP_UPDATE: 'VIEW_APP_UPDATE',
  EDIT_APP_UPDATE: 'EDIT_APP_UPDATE',
  VIEW_HBP_EARN: 'VIEW_HBP_EARN',
  ADD_HBP_EARN: 'ADD_HBP_EARN',
  EDIT_HBP_EARN: 'EDIT_HBP_EARN',
  VIEW_HBP_REDEEM: 'VIEW_HBP_REDEEM',
  ADD_HBP_REDEEM: 'ADD_HBP_REDEEM',
  EDIT_HBP_REDEEM: 'EDIT_HBP_REDEEM',
  VIEW_PROVISIONAL_CATEGORY: 'VIEW_PROVISIONAL_CATEGORY',
  ADD_PROVISIONAL_CATEGORY: 'ADD_PROVISIONAL_CATEGORY',
  EDIT_PROVISIONAL_CATEGORY: 'EDIT_PROVISIONAL_CATEGORY',
  ADC_SETUP_MENU: 'ADC_SETUP_MENU',
  ADC_COVERAGE_MENU: 'ADC_COVERAGE_MENU',
  LOGISTIC_TRACKING_MENU: 'LOGISTIC_TRACKING_MENU',
  VIEW_LOGISTIC_TRACKING: 'VIEW_LOGISTIC_TRACKING',
  SEARCH_LOGISTIC_TRACKING: 'SEARCH_LOGISTIC_TRACKING',
  VIEW_HB_PRIME_MENU: 'VIEW_HB_PRIME_MENU',
  VIEW_PRIME_PLANS_LIST: 'VIEW_PRIME_PLANS_LIST',
  ADD_PRIME_PLAN: 'ADD_PRIME_PLAN',
  EDIT_PRIME_PLAN: 'EDIT_PRIME_PLAN',
  VIEW_HB_PRIME_MEMBERS_MENU: 'VIEW_HB_PRIME_MEMBERS_MENU',
  VIEW_PRIME_MEMBERS: 'VIEW_PRIME_MEMBERS',
  VIEW_MEMBERSHIP_LIST: 'VIEW_MEMBERSHIP_LIST',
  VIEW_SYSTEM_PROPERTY_MENU: 'VIEW_SYSTEM_PROPERTY_MENU',
  VIEW_SYSTEM_PROPERTY: 'VIEW_SYSTEM_PROPERTY',
  ADD_SYSTEM_PROPERTY: 'ADD_SYSTEM_PROPERTY',
  EDIT_SYSTEM_PROPERTY: 'EDIT_SYSTEM_PROPERTY',
  VIEW_SEO_SETTING_MENU: 'VIEW_SEO_SETTING_MENU',
  VIEW_SEO_SETTING: 'VIEW_SEO_SETTING',
  ADD_SEO_SETTING: 'ADD_SEO_SETTING',
  EDIT_SEO_SETTING: 'EDIT_SEO_SETTING',
  VIEW_ADC_PINCODE_MENU: 'VIEW_ADC_PINCODE_MENU',
  VIEW_ADC_PINCODE: 'VIEW_ADC_PINCODE',
  ADD_ADC_PINCODE: 'ADD_ADC_PINCODE',
  EDIT_ADC_PINCODE: 'EDIT_ADC_PINCODE',
  DELETE_ADC_PINCODE: 'DELETE_ADC_PINCODE',
  VIEW_ADC_ASSIGNED_SKIDS: 'VIEW_ADC_ASSIGNED_SKIDS',
  VIEW_ADC_INVENTORY: 'VIEW_ADC_INVENTORY',
  EDIT_ADC_INVENTORY: 'EDIT_ADC_INVENTORY',
  CHECK_PAST_ORDERS: 'CHECK_PAST_ORDERS',
  VIEW_CRON_JOBS: 'VIEW_CRON_JOBS',
  START_CRON_JOBS: 'START_CRON_JOBS',
  VIEW_SHORT_URLS: 'VIEW_SHORT_URLS',
  ADD_SHORT_URL: 'ADD_SHORT_URL',
  DELETE_SHORT_URL: 'DELETE_SHORT_URL',
};

let authCodes = null;

const setAuthCodes = (menuList = []) => {
  const codes = {};
  menuList.map(({ menu, menuSectionList }) => {
    if (menu && !codes[menu]) codes[menu] = {};
    menuSectionList.map(({ menuSection, menuAuthorityCodes }) => {
      codes[menu][menuSection] = menuAuthorityCodes.map(ac => ac.authorityCode);
    });
  });
  authCodes = codes;
};

const isAuthorisedUser = code => {
  const { menu, section, authority } = code;
  const authMenu = authCodes[menu];
  const menuSection = (authCodes[menu] && authCodes[menu][section]) || null;
  return (
    (menuSection &&
      (menuSection.indexOf(authority) > -1 || authority === true)) ||
    (!menuSection && authMenu && section === true && authority === true)
  );
};

const getAuthCodes = () => (authCodes && { ...authCodes }) || null;

export default {
  MENU_SET,
  MENU_SECTIONS,
  MENU_AUTH_CODE,
  setAuthCodes,
  isAuthorisedUser,
  getAuthCodes,
};
