import uid from 'uid';
import { getItem, setItem } from './localStorageService';
import moment from 'moment';
import { ATTRIBUTE_UI_TYPE } from './ConstantsService';

export const getRandomInteger = (length = 5) => {
  const multiplier = 100000;
  return Math.round(Math.random() * multiplier);
};

export const getFileName = file => {
  const { uid = `${getRandomInteger()}-${getRandomInteger()}` } = file;
  const textArray = file.name.split('.');
  return `${uid}.${textArray[textArray.length - 1]}`;
};

export const generateSku = categoryId => {
  const categories = [
    { id: 1, name: 'chicken', prefix: 'CHK-' },
    { id: 2, name: 'mutton', prefix: 'MUT-' },
    { id: 3, name: 'fish', prefix: 'SF-' },
    { id: 4, name: 'egg', prefix: 'EGG-' },
  ];
  let sku = 'DEF-';
  categories.map(cat => {
    if (cat.id === categoryId) sku = cat.prefix;
  });
  return sku + uid(4);
};

export const csvStringToArray = (keyList, jsonField) => {
  const processedJson = { ...jsonField };
  keyList.map(({ key, isNumeric }) => {
    if (processedJson[key] && typeof processedJson[key] === typeof '') {
      try {
        let value = processedJson[key].split(',');
        if (isNumeric) {
          value = value.map(v => parseInt(v));
        }
        processedJson[key] = value;
      } catch (e) {
        console.log('Failed to convert string to array: ', processedJson[key]);
      }
    } else if (undefined !== processedJson[key] && processedJson[key] === '') {
      delete processedJson[key];
    }
  });
  return processedJson;
};

export const arrayToCsvString = (keyList, jsonField) => {
  const processedJson = { ...jsonField };
  keyList.map(({ key }) => {
    if (processedJson[key] && typeof processedJson[key] === typeof []) {
      try {
        processedJson[key] = processedJson[key].join(',');
      } catch (e) {
        console.log('Failed to convert array to string: ', processedJson[key]);
      }
    }
  });
  return processedJson;
};

const generateUUID = () => {
  const uuid = uid(16);
  setItem('crm-did', uuid);
  return uuid;
};

export const getDeviceId = () => {
  return getItem('crm-did') || generateUUID();
};

export const filterDropdown = (input, option) =>
  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

export const disablePastDates = current =>
  current.isBefore(new Date()) && !current.isSame(new Date(), 'day');

export const hasSomeValues = (obj, keys = []) => {
  if (!keys?.length) keys = Object.keys(obj);
  const filtered = keys.filter(
    key => Object.hasOwnProperty(key) && obj[key] !== undefined
  );
  return filtered.length > 0;
};

export const hasAllValues = (obj, keys = []) => {
  if (!keys?.length) return false;
  const filtered = keys.filter(
    key => Object.hasOwnProperty(key) && obj[key] !== undefined
  );
  return filtered.length === keys.length;
};

export function jsonToQueryString(json) {
  return (
    '?' +
    Object.keys(json)
      .map(function(key) {
        return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
      })
      .join('&')
  );
}

export function queryStringToJSON(qstring) {
  // eslint-disable-line
  if (!qstring) return {};
  const qs = qstring[0] === '?' ? qstring.substring(1) : qstring;
  const pairs = qs.split('&');
  const result = {};
  pairs.forEach(p => {
    const pair = p.split('=');
    const key = pair[0];
    const value = decodeURIComponent(p.substring(pair[0].length + 1) || '');
    if (result[key]) {
      if (Object.prototype.toString.call(result[key]) === '[object Array]') {
        result[key].push(value);
      } else {
        result[key] = [result[key], value];
      }
    } else {
      result[key] = value;
      if (value === 'false') result[key] = false;
      if (value === 'true') result[key] = true;
    }
  });
  return JSON.parse(JSON.stringify(result));
}

export function checkBetween(startDate, endDate) {
  // const from = moment(startDate).format('YYYY-MM-DD');
  // const to = moment(endDate).format('YYYY-MM-DD');
  // const currentDate = moment(new Date()).format('YYYY-MM-DD');
  // return moment(currentDate).isBetween(from, to);
  return moment().isBetween(startDate, endDate);
}

export const copyPlainStringToClipboard = (
  string = '',
  successMessage = 'Copied to Clipboard'
) => {
  try {
    const el = document.createElement('textarea');
    el.value = string;
    el.setAttribute('readonly', '');
    el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    return Promise.resolve({ message: successMessage });
  } catch (e) {
    return Promise.reject({ message: 'Error copying code.' });
  }
};

export const getImageUuidFromUrl = url =>
  url
    .split('/')
    .reverse()[0]
    .split('.')[0];

export const getUiType = uiType =>
  ATTRIBUTE_UI_TYPE.find(ut => ut.value === uiType)?.label ||
  'UI Type Not Found';
