import request from '../../../util/request';

export const getCategoriesForProductApi = () =>
  request.get('/crm/categoryToCreateProduct');

export const getAttributesBySubcategoryForProductApi = subcategoryId =>
  request.get('/crm/attributesBySubcategoryForProduct/' + subcategoryId);

export const postProductApi = data => request.post('/crm/product', data);

export const putProductApi = data => request.put('/crm/product', data);

export const getAllProductsApi = () => request.get('/crm/products');

export const getProductByIdApi = productId =>
  request.get(`/crm/product/${productId}`);

export const getProductPriceDetailsApi = ({ productId, cityId }) =>
  request.get(
    `/crm/product/${productId}/pricingPreSaleAndAdcDetails/${cityId}`
  );

export const updateProductPriceDetailsApi = data =>
  request.put(
    `/crm/product/pricingPreSaleAndAdcDetails/${data.productId}`,
    data
  );

export const getAdcwiseProductDealsDetailsApi = ({ productId, adcCode }) =>
  request.get(`/crm/product/${productId}/deal/${adcCode}`);

export const putAdcwiseProductDealsDetailsApi = data =>
  request.put(`/crm/product/deal/${data.productId}`, data);

export const searchSkuApi = data => request.post('/crm/searchInventory', data);

export const getAllPresentAndFuturePresaleApi = ({ productId, cityId }) =>
  request.get(
    `/crm/product/${productId}/getAllPresentAndFuturePresale/${cityId}`
  );

export const newPresaleLedgerDetailsApi = data =>
  request.post('/crm/product/newPresaleLedgerDetails', data);

export const bulkPresaleGenerationApi = data =>
  request.post('/crm/product/newPresaleLedgerDetailsList', data);

export const deletePresaleLedgerDetailsApi = productId =>
  request.del(`/crm/product/deletePresaleDate/${productId}`);

export const putToggleWeekendSplSkuApi = ({ status }) =>
  request.put(`/crm/product/setWeekendSpecialStatus?status=${status}`);

export const getWeekendSplStatus = () =>
  request.get('/crm/provisionalCategory/getWeekendSpecialStatus');

export const getAllCategoriesApi = () => request.get('/crm/category');
export const postCategoryApi = data => request.post('/crm/category', data);
export const putCategoryApi = data => request.put('/crm/category', data);
export const deleteCategoryApi = categoryId =>
  request.del(`/crm/category/${categoryId}`);

export const getAllSubcategoriesApi = () => request.get('/crm/subCategory');
export const postSubcategoryApi = data =>
  request.post('/crm/subCategory', data);
export const putSubcategoryApi = data => request.put('/crm/subCategory', data);
export const deleteSubcategoryApi = subCatId =>
  request.del(`/crm/subCategory/${subCatId}`);

export const getAllProductClassApi = () => request.get('/crm/productClass');
export const postProductClassApi = data =>
  request.post('/crm/productClass', data);
export const putProductClassApi = data =>
  request.put('/crm/productClass', data);
export const deleteProductClassApi = pcId =>
  request.del(`/crm/productClass/${pcId}`);

export const getAllAttributesApi = () => request.get('/crm/productAttributes');

export const getSubCategoryAttributesApi = () =>
  request.get('/crm//subcategoryProductAttributes');

export const postSubCategoryProductAttributesApi = data =>
  request.post('/crm/subcategoryProductAttributes', data);

export const deleteSubCategoryProductAttributesApi = data =>
  request.del('/crm/subcategoryProductAttributes', data);

export const upsertAttributeApi = data =>
  request.put('/crm/productAttributes', data);

export const upsertAttributeOptionApi = data =>
  request.put('/crm/productAttributeOption', data);
