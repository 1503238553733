import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import './index.scss';
// import App from './App';
import * as serviceWorker from './serviceWorker';
import ErrorReportService from './util/ErrorReportService';

/** redux */
import { Provider } from 'react-redux';
import configureStore from './redux/store';
import rootSaga from './redux/root.saga';
import history from './redux/history';
import Loader from './components/Loader';
const AppComponent = React.lazy(() =>
  import('./App' /* webpackChunkName: "app" */)
);

const store = configureStore();
store.runSaga(rootSaga);

ErrorReportService.initialize();

ReactDOM.render(
  <Router history={history}>
    <Provider store={store}>
      <Suspense fallback={<Loader />}>
        <AppComponent />
      </Suspense>
    </Provider>
  </Router>,
  document.getElementById('root')
);

export { store };

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
